import React from 'react';
import { Link } from 'react-router-dom';
import "../css/PartDumas.css";
import { useTranslation } from 'react-i18next';

function HeaderPartDumas() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <header className="header headre_partDumas">
                <div className="container px-5">
                <div className="row gx-5 justify-content-center">
                    <div className="col-lg-12">
                    <div className="text-center my-5">
                        <p className="display-6 fw-bolder text-white mb-2">{t('prat_dumas_since_1309')}</p>                        
                    </div>
                    </div>
                </div>
                </div>
            </header>
        </>
    )
}

export default HeaderPartDumas;