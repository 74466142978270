import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

import enTranslation from './locales/en.json';
import frTranslation from './locales/fr.json';


const resources = {
  en: {
    translation: enTranslation
  },
  fr: {
    translation: frTranslation
  }
};

// i18n
//   .use(Backend)
//   .use(initReactI18next)
//   .init({
//     fallbackLng: 'fr',
//     debug: true,
//     interpolation: {
//       escapeValue: false,
//     },
//     backend: {
//       loadPath: '/locales/{{lng}}/translation.json', // Adjust the path based on your project structure
//     },
//   });


i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('language') != null ? localStorage.getItem('language') : 'fr', // Default language
    fallbackLng: localStorage.getItem('language') != null ? localStorage.getItem('language') : 'fr',
    interpolation: {
      escapeValue: false
    }
});

export default i18n;
