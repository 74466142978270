import React, { useState, useEffect } from 'react';
import footerLogo from '../images/footerLogo.png';
import footerLogo1 from '../images/Made_in_France.png';
import footerLogo2 from '../images/300logoOK.png';
import LogoPdf from '../images/pdf.png'
import { Link } from 'react-router-dom';
import '../css/FooterStyle.css';
import '../css/AboveFooter.css';
import { BASE_URL, BASE_URL_PUBLIC } from "./config/keys";
import { useTranslation } from 'react-i18next';

import axios from 'axios';

const Footer = () => {
    const { t, i18n } = useTranslation();
    
    const [socialMediaData, setSocialMediaData] = useState(null);
    const [pageInfos, setPageInfos] = useState(null);
    const [cookieAccepted, setCookieAccepted] = useState(localStorage.getItem('cookieAccepted'));
    useEffect(()=>{
        async function fetchData(){
            try {
                const response = await axios.get(BASE_URL+'social_media?lang='+i18n.language);
                setSocialMediaData(response.data.data);                     
            } catch (error) {
                console.error('Error fetching social media data:', error);
            }
        }
        
        async function fetchData1(){
            try {
                const response1 = await axios.get(BASE_URL+'gestion-de-page?lang='+i18n.language);
                setPageInfos(response1.data.data);       
            } catch (error) {
                console.error('Error fetching social media data:', error);
            }
        }
        fetchData();        
        fetchData1();        
    }, [i18n.language]);

    if (!socialMediaData) {
        return <div>Loading...</div>;
    }
    const acceptCondition = () => {
        setCookieAccepted(1)
        localStorage.setItem('cookieAccepted', 1);
    };
    return (
        <> 
        <div className="pb-5 pt-2 px-0 mb-4 above_footer">
                <div>
                    <div className="container div_pdf row">
                        <div className="col-2"></div>
                        <div className="col-4">
                            <h4 className="text-white mb-0 text-center" style={{ fontFamily: 'Poppins bold' }}>{t('notre_catalogue')}</h4>
                            <div className="align-items-center text-center">
                                <Link target="_blank" to={BASE_URL_PUBLIC+'uploads/pdf/catalogs/'+pageInfos?.catalog_pdf}>
                                <img
                                    src={LogoPdf}
                                    alt="Logo"
                                    className="img-fluid"
                                    style={{ zoom: '7%', marginBottom: '-7%' }}
                                />
                                </Link>
                                {/* <div className="mt-3">
                                    Nulam condimentum varius ipsun at viverra. Donec tortor metu
                                </div> */}
                            </div>
                        </div>
                        <div className="col-4">
                            <h4 className="text-white mb-0 text-center" style={{ fontFamily: 'Poppins bold' }}>{t('specification_techniques')}</h4>
                            <div className="align-items-center text-center">
                                <Link target="_blank" to={BASE_URL_PUBLIC+'uploads/pdf/specifications/'+pageInfos?.specification_pdf}>
                                <img
                                    src={LogoPdf}
                                    alt="Logo"
                                    className="img-fluid"
                                    style={{ zoom: '7%', marginBottom: '-7%' }}
                                />
                                </Link>
                                {/* <div className="mt-3">
                                    Nulam condimentum varius ipsun at viverra. Donec tortor metu
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>                     
            
            <footer className="py-5 footer">
                <div className="container px-5 div_footer">
                    <div className="container-fluid">
                        <h4 className="text-white">{t('contact')}</h4>
                        <Link className="navbar-brand text-dark" to="/contact"><p style={{color:'#7183A5'}}>{t('Contact page')}</p></Link>
                        <p className="text-white"><i className="fa-solid fa-envelope"></i> { pageInfos?.email }</p>
                        <p className="text-white"><i className="fa-solid fa-phone"></i> { pageInfos?.tel_fr }</p>
                        <p className="text-white"><i className="fa-solid fa-map"></i> 
                            <span className='p_adresse' style={{display:'inline-grid',marginLeft:'5px'}} dangerouslySetInnerHTML={{ __html: pageInfos?.adresse_fr }}></span>
                        </p>
                    </div>
                    <div className="container-fluid">
                        <h4 className="text-white">{t('Categories')}</h4>
                        <Link className="navbar-brand text-dark" to="/"><p style={{color:'#7183A5'}}>{t('home')}</p></Link>
                        <Link className="navbar-brand text-dark" to="/products"><p style={{color:'#7183A5'}}>{t('products')}</p></Link>
                        <Link className="navbar-brand text-dark" to="/part_dumas"><p style={{color:'#7183A5'}}>{t('prat_dumas')}</p></Link>
                    </div>
                    <div className="container-fluid">
                        <h4 className="text-white">{t('Nous suivre')}</h4>                        
                        <div className='div_rs'>
                            {socialMediaData &&(
                                <>
                                    {socialMediaData.facebook_page && (
                                        <a href={socialMediaData.facebook_page} target="_blank" rel="noopener noreferrer" className="text-white px-2">
                                            <i className="fab fa-facebook-square fa-1x"></i>
                                        </a>
                                    )}  
                                    {socialMediaData.instagram && (
                                        <a href={socialMediaData.instagram} target="_blank" rel="noopener noreferrer" className="text-white px-2">
                                            <i className="fa-brands fa-instagram fa-1x"></i>
                                        </a>
                                    )}  
                                    {socialMediaData.twitter && (                                  
                                        <a href={socialMediaData.twitter} target="_blank" rel="noopener noreferrer" className="text-white px-2">
                                            {/* <i className="fab fa-twitter-square fa-1x"></i> */}
                                            <i className="fab fa-x-twitter fa-1x"></i>
                                        </a>
                                    )}
                                    {socialMediaData.linkedin && (
                                        <a href={socialMediaData.linkedin} target="_blank" rel="noopener noreferrer" className="text-white px-2">
                                            <i className="fa-brands fa-linkedin"></i>
                                        </a>
                                    )}
                                    {socialMediaData.vimeo && (
                                        <a href={socialMediaData.vimeo} target="_blank" rel="noopener noreferrer" className="text-white px-2">
                                            <i className="fa-brands fa-youtube"></i>
                                        </a>
                                    )} 
                                </>
                            )}
                        </div>
                        <br/>
                        <h6 className="text-white">
                            Site créé par 
                            <a className='mb-0 mt-3 fw-bold title_card' style={{cursor:'pointer'}} target="_blank" href="https://easywebmobile.fr/"> 
                            <img className="img-responsive py-2 mt-3" src={footerLogo2} alt="Website Logo" style={{ width: '230px' }} />
                            </a>
                        </h6>
                    </div>
                    <div className="container-fluid">
                        {/* Assuming your logo is an image, you can replace the content accordingly */}
                        <Link target='_blank' className="navbar-brand" to="https://www.patrimoinevivantnouvelleaquitaine.com/fiche-entreprise/sarl-prat-dumas.html" style={{ textDecoration: 'none' }}>
                        <img className="img-responsive py-2" src={footerLogo} alt="Website Logo" style={{ width: '230px' }} />
                        </Link>
                        <br></br>
                        <img className="img-responsive py-2" src={footerLogo1} alt="Website Logo" style={{ width: '230px' }} />
                    </div>
                </div>
                {
                    cookieAccepted != 1 ?
                    <div id="cookie-alert-container">
                        <div className="cookie">
                            <h3>{t('Votre vie privée')} <i className="fa fa-lock"></i></h3>
                            <div className="description">
                                <p>{t('cookie_text')}</p>
                                <div className="form-group">
                                    <button onClick={() => acceptCondition()} className="darkbtn">J'accepte</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
                }
            </footer>
        </>
    );
}

export default Footer;
