
import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import HeaderPartDumas from './HeaderPartDumas';
import { BASE_URL, BASE_URL_PUBLIC } from "../Components/config/keys";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PartDumas = () => {
    const { t, i18n } = useTranslation();
    const [news, setNews] = useState([]);
    const [historic, setHistoric] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseNews = await axios.get(`${BASE_URL}prat-dumas-by-type/news?lang=${i18n.language}`);
                const sortedNews = responseNews.data.data.sort((a, b) => new Date(b.date) - new Date(a.date));
                
                const responseHistoric = await axios.get(`${BASE_URL}prat-dumas-by-type/historic?lang=${i18n.language}`);
                setNews(sortedNews);
                setHistoric(shuffleArray(responseHistoric.data.data));
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error here (show error message or other logic)
            }
        };
    
        fetchData();
    }, [i18n.language]);

    const shuffleArray = (array) => {
        let shuffledArray = array.slice(); // Create a copy of the array
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };

    const renderCarouselItem = (items) => {
        // Calculer le nombre de colonnes nécessaires
        const numColumns = 3;
        
        // Filtrer les items pour ne garder que ceux qui ont un ID valide
        const filteredItems = items.filter(item => item.id);
    
        // Tableau pour stocker tous les items à afficher, y compris les vides si nécessaire
        let renderedItems = [];
    
        for (let index = 0; index < filteredItems.length; index += numColumns) {
            renderedItems.push(
                <Carousel.Item key={index}>
                    <div className="container">
                        <div className="row">
                            {[0, 1, 2].map((colIndex) => {
                                const itemIndex = (index + colIndex) % filteredItems.length; // Utilisation du modulo pour répéter les items
                                const item = filteredItems[itemIndex];
                                return (
                                    <div className="col-md-4 mb-4" key={colIndex}>
                                        <div className="card h-100 border-0">
                                            <Link to={`/prat_dumas_detail/${item.id}`} className="text-decoration-none">
                                                <img
                                                    className="card-img-top img-fluid"
                                                    src={`${BASE_URL_PUBLIC}uploads/photos/homapage/${item.id}/${item.piece_joint}`}
                                                    alt={`Card ${colIndex + 1}`}
                                                    style={{ height: "193px", width: "100%", objectFit: 'cover' }}
                                                    onError={(e) => { e.target.onerror = null; e.target.src = '/path/to/default/image.jpg'; }}
                                                />
                                            </Link>
                                            <div className="card-body">
                                                <Link to={`/prat_dumas_detail/${item.id}`} className="text-decoration-none">
                                                    <p className='mb-0 mt-3 fw-bold title_card' style={{ color: '#04D9D9' }}>{item.title}</p>
                                                </Link>
                                                <p dangerouslySetInnerHTML={{ __html: item.petit_description }}></p>
                                            </div>
                                            <div className="card-footer bg-transparent d-flex justify-content-end align-items-center">
                                                <Link className="btn btn-primary mt-3"
                                                    to={`/prat_dumas_detail/${item.id}`}
                                                    style={{ backgroundColor: '#04D9D9', color: 'white', border: 'none' }}>
                                                    {t('Voir')} <FontAwesomeIcon icon={faEye} className="ms-2" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Carousel.Item>
            );
        }
    
        return renderedItems;
    };

    if (news.length === 0 || historic.length === 0) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <HeaderPartDumas />
            <section className="py-0 pb-5">
                <div className="container pb-5">
                    <h2 className="h4 fw-bolder pt-5" style={{ color: '#324061' }}>{t('Our Actuality')}</h2>
                </div>
                <Carousel indicators={false} prevIcon={<span className="carousel-control-prev-icon" />} nextIcon={<span className="carousel-control-next-icon" />}>
                    {renderCarouselItem(news)}
                </Carousel>
            </section>
            <section className="py-0 pb-5">
                <div className="container pb-5">
                    <h2 className="h4 fw-bolder" style={{ color: '#324061' }}>{t('Our History')}</h2>
                </div>
                <Carousel indicators={false} prevIcon={<span className="carousel-control-prev-icon" />} nextIcon={<span className="carousel-control-next-icon" />}>
                    {renderCarouselItem(historic)}
                </Carousel>
            </section>
        </>
    );
}

export default PartDumas;
