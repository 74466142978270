import React from 'react';

const CustomArrow = ({ className, style, onClick, direction }) => (
  <div
    className={className}
    style={{ ...style, display: 'block', padding: '15px', borderRadius: '50%', position: 'absolute', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
    onClick={onClick}
  >
    {direction === 'prev' ? '<' : '>'}
  </div>
);

export default CustomArrow;
