// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import { BASE_URL, BASE_URL_PUBLIC } from "../Components/config/keys";
// import { useTranslation } from 'react-i18next';

// function ProductDetail() {
//     const { t, i18n } = useTranslation();
//     const { id } = useParams();
//     const [product, setProduct] = useState(null);

//     useEffect(() => {
//         async function fetchProduct() {
//             try {
//                 const response = await axios.get(BASE_URL+'products-by-id/'+id+'?lang='+i18n.language);
//                 setProduct(response.data.data);
//             } catch (error) {
//                 console.error('Error fetching product:', error);
//             }
//         }

//         fetchProduct();
//     }, [id, i18n.language]);

//     if (!product) {
//         return <div>Loading...</div>;
//     }

//     return (
//         <>
//             {product && (
//                 <div className="container mb-5 mt-4">
//                     <div className="col-md-12">
//                         <section className="panel">
//                             <div className="panel-body row ml-3">
//                                 <div className="col-md-6 col-sm-12" style={{ width: "auto" }}>
//                                     <div className="" style={{ maxWidth: "400px" }}>
//                                         {product.photo && (
//                                             <img class="img-fluid img-thumbnail" src={BASE_URL_PUBLIC + 'uploads/photos/articles/' + product.photo} alt={product.title} 
//                                                 style={{ height: "auto" }}
//                                             />
//                                         )}
//                                     </div>
//                                 </div>
//                                 <div className="col-md-6  col-sm-12 mt-2 ml-0">
//                                     {product.title && (
//                                         <h4 className="pro-d-title" style={{fontSize: '18px'}}>
//                                             {product.title}
//                                         </h4>
//                                     )}
//                                     {product.description && (
//                                         <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
//                                     )}
//                                 </div>
//                             </div>
//                         </section>
//                     </div>
//                 </div>
//             )}
//         </>
//     );
// }

// export default ProductDetail;


import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, BASE_URL_PUBLIC } from "../Components/config/keys";
import { useTranslation } from 'react-i18next';

function ProductDetail() {
    const { t, i18n } = useTranslation();
    const { locale, slug } = useParams(); // Récupérer le locale et le slug
    const [product, setProduct] = useState(null);
    const [slugUrlFr, setSlugUrlFr] = useState(slug);
    const [slugUrlEn, setSlugUrlEn] = useState(slug);
    const [currentSlug, setCurrentSlug] = useState(slug); // Track the current slug based on language

    // Fetch product data based on the current slug
    async function fetchProduct(slugToFetch) {
        console.log("Fetching product data for slug:", slugToFetch);
        try {
            const response = await axios.get(`${BASE_URL}${i18n.language}/article/${slugToFetch}`);
            const { url_en, url_fr } = response.data.data;

            setProduct(response.data.data);
            setSlugUrlEn(url_en);
            setSlugUrlFr(url_fr);
        } catch (error) {
            console.error("Error fetching product:", error);
        }
    }

    // Initial product fetch based on slug or locale
    useEffect(() => {
        fetchProduct(slug);
    }, [slug, locale]); // Triggered on slug or locale changes

    useEffect(() => {
        const newSlug = i18n.language === "fr" ? slugUrlFr : slugUrlEn;
        setCurrentSlug(newSlug);

        // Fetch data only if the slug changes
        if (newSlug !== slug) {
            fetchProduct(newSlug);
        }
    }, [i18n.language, slugUrlFr, slugUrlEn]); 
    

  
    if (!product) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {product && (
                <div className="container mb-5 mt-4">
                    <div className="col-md-12">
                        <section className="panel">
                            <div className="panel-body row ml-3">
                                <div className="col-md-6 col-sm-12" style={{ width: "auto" }}>
                                    <div className="" style={{ maxWidth: "400px" }}>
                                        {product.photo && (
                                            <img className="img-fluid img-thumbnail" src={`${BASE_URL_PUBLIC}uploads/photos/articles/${product.photo}`} alt={product.title} 
                                                style={{ height: "auto" }}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 mt-2 ml-0">
                                    {product.title && (
                                        <h4 className="pro-d-title" style={{ fontSize: '18px' }}>
                                            {product.title}
                                        </h4>
                                    )}
                                    {product.description && (
                                        <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
                                    )}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )}
        </>
    );
}

export default ProductDetail;


// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import { BASE_URL,BASE_URL_PUBLIC } from "../Components/config/keys";

// function ProductDetail() {
//     const {locale, slug } = useParams(); // Récupérer le slug
//     const [product, setProduct] = useState(null);

//     useEffect(() => {
//         async function fetchProduct() {
//             if (!slug) return;

//             try {
//                 const response = await axios.get(`${BASE_URL}${locale}/article/${slug}`); // Assurez-vous que l'API accepte le slug
//                 setProduct(response.data.data);
//             } catch (error) {
//                 console.error('Error fetching product:', error);
//             }
//         }

//         fetchProduct();
//     }, [slug]);

//     if (!product) {
//         return <div>Loading...</div>;
//     }

//     return (
//         <>
//             {product && (
//                 <div className="container mb-5 mt-4">
//                     <div className="col-md-12">
//                         <section className="panel">
//                             <div className="panel-body row ml-3">
//                                 <div className="col-md-6 col-sm-12" style={{ width: "auto" }}>
//                                     <div className="" style={{ maxWidth: "400px" }}>
//                                         {product.photo && (
//                                             <img 
//                                                 className="img-fluid img-thumbnail" 
//                                                 src={`${BASE_URL_PUBLIC}uploads/photos/articles/${product.photo}`} 
//                                                 alt={product.title} 
//                                                 style={{ height: "auto" }}
//                                             />
//                                         )}
//                                     </div>
//                                 </div>
//                                 <div className="col-md-6 col-sm-12 mt-2 ml-0">
//                                     {product.title && (
//                                         <h4 className="pro-d-title" style={{fontSize: '18px'}}>
//                                             {product.title}
//                                         </h4>
//                                     )}
//                                     {product.description && (
//                                         <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
//                                     )}
//                                 </div>
//                             </div>
//                         </section>
//                     </div>
//                 </div>
//             )}
//         </>
//     );
// }

// export default ProductDetail;