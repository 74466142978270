import ReactGA from 'react-ga4';

// Initialise Google Analytics
export const initGA = () => {
  ReactGA.initialize('G-J3J6G9RSF1'); // ID Analytics
};

// Suivre une page
export const trackPage = (page) => {
  ReactGA.send({ hitType: 'pageview', page });
};
