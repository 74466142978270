// import React from 'react';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import './css/Styles.css';
// /* import "bootstrap/dist/css/bootstrap.min.css"; */
// import Nav from './Components/Nav';
// import Footer from './Components/Footer';
// import Home from './Components/Home';
// import Products from './layouts/Products';
// import Contact from './layouts/Contact';
// import PartDumas from './layouts/PartDumas';
// import NotFound from './Components/NotFound';
// import ProductDetail from './layouts/ProductDetail';
// import PratDumasDetail from './layouts/PratDumasDetail';
// import ProductsSearch from './layouts/ProductsSearch';
// import ScrollToTop from './layouts/ScrollToTop';


// const DefaultLayout = ({ children }) => (
//     <>
//      {/* navbar */}
//       <Nav />
//       {children}
//       {/* Footer */}
//       <Footer />
//     </>
//   );
  
//   const EmptyLayout = ({ children }) => <>{children}</>;

// const App = () => {
//     return (
//         <>
//             <div>
//                 <BrowserRouter>
//                 <ScrollToTop /> {/* Intégration du composant ScrollToTop */}

//                     <div>
//                         <Routes>
//                             <Route path="/" index element={ 
//                                 <DefaultLayout>
//                                     <Home />
//                                 </DefaultLayout>} 
//                             />
//                             <Route path="/products" element={
//                                 <DefaultLayout>
//                                     <Products />
//                                 </DefaultLayout>}
//                             />
//                                 <Route path="produit-liste" element={
//                                       <DefaultLayout>
//                                     <ProductsSearch />
//                                     </DefaultLayout>} />

//                             <Route path="/part_dumas" element={
//                                 <DefaultLayout>
//                                     <PartDumas />
//                                 </DefaultLayout>}
//                             />
//                             <Route path="/contact" element={
//                                 <DefaultLayout>
//                                     <Contact />
//                                 </DefaultLayout>}
//                             />                            
//                             <Route path="/product_detail/:id" element={
//                                 <DefaultLayout>
//                                     <ProductDetail />
//                                 </DefaultLayout>}
//                             />                            
//                             <Route path="/prat_dumas_detail/:id" element={
//                                 <DefaultLayout>
//                                     <PratDumasDetail />
//                                 </DefaultLayout>}
//                             />                              
//                             <Route path="/produit-liste/:id" element={
//                                 <DefaultLayout>
//                                     <ProductsSearch />
//                                 </DefaultLayout>}
//                             />                           
//                             <Route path="*" element={<EmptyLayout><NotFound /></EmptyLayout>} />                            
//                         </Routes>
                    
//                         {/* Bootstrap core JS */}
//                         <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
//                         {/* SB Forms JS */}
//                         <script src="https://cdn.startbootstrap.com/sb-forms-latest.js"></script>
//                     </div>
//                 </BrowserRouter>
//             </div>
//         </>
//     );
// };

// export default App;




import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './css/Styles.css';
/* import "bootstrap/dist/css/bootstrap.min.css"; */
import Nav from './Components/Nav';
import Footer from './Components/Footer';
import Home from './Components/Home';
import Products from './layouts/Products';
import Contact from './layouts/Contact';
import PartDumas from './layouts/PartDumas';
import NotFound from './Components/NotFound';
import ProductDetail from './layouts/ProductDetail';
import PratDumasDetail from './layouts/PratDumasDetail';
import ProductsSearch from './layouts/ProductsSearch';
import ProductDetailid from './layouts/Productdetailsbyid';
import ScrollToTop from './layouts/ScrollToTop';
import { initGA, trackPage } from './utils/analytics'; // Import des fonctions Analytics

// Layouts pour l'affichage global
const DefaultLayout = ({ children }) => (
    <>
        {/* Navbar */}
        <Nav />
        {children}
        {/* Footer */}
        <Footer />
    </>
);

const EmptyLayout = ({ children }) => <>{children}</>;

// Composant pour suivre les pages
const AnalyticsWrapper = () => {
    const location = useLocation();

    useEffect(() => {
        // Suivi des pages à chaque changement de route
        trackPage(location.pathname + location.search);
    }, [location]);

    return null;
};

const App = () => {
    // Initialisation de Google Analytics au chargement de l'application
    useEffect(() => {
        initGA();
    }, []);

    return (
        <>
            <div>
                <BrowserRouter>
                    <AnalyticsWrapper /> {/* Composant pour le suivi des pages */}
                    <ScrollToTop /> {/* Gestion du scroll en haut à chaque navigation */}

                    <div>
                        <Routes>
                            <Route
                                path="/"
                                index
                                element={
                                    <DefaultLayout>
                                        <Home />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/products"
                                element={
                                    <DefaultLayout>
                                        <Products />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="produit-liste"
                                element={
                                    <DefaultLayout>
                                        <ProductsSearch />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/part_dumas"
                                element={
                                    <DefaultLayout>
                                        <PartDumas />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/contact"
                                element={
                                    <DefaultLayout>
                                        <Contact />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/product_detail/:id"
                                element={
                                    <DefaultLayout>
                                        <ProductDetailid />
                                    </DefaultLayout>
                                }
                            />
 

 <Route path="/" element={<Products />} />
                <Route path="/:locale/article/:slug"  element={
                                    <DefaultLayout>
                                        <ProductDetail />
                                    </DefaultLayout>} />    



                         <Route
                                path="/prat_dumas_detail/:id"
                                element={
                                    <DefaultLayout>
                                        <PratDumasDetail />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="/produit-liste/:id"
                                element={
                                    <DefaultLayout>
                                        <ProductsSearch />
                                    </DefaultLayout>
                                }
                            />
                            <Route
                                path="*"
                                element={
                                    <EmptyLayout>
                                        <NotFound />
                                    </EmptyLayout>
                                }
                            />
                        </Routes>

                        {/* Bootstrap core JS */}
                        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
                        {/* SB Forms JS */}
                        <script src="https://cdn.startbootstrap.com/sb-forms-latest.js"></script>
                    </div>
                </BrowserRouter>
            </div>
        </>
    );
};

export default App;
