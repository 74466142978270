import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../css/Header.css';
import { useTranslation } from 'react-i18next';

function Header(){    
    const { t, i18n } = useTranslation();
    return (
        <>
            <header className="header">
                <div className="container px-5">
                <div className="row gx-5 justify-content-center">
                    <div className="col-lg-12">
                    <div className="text-center my-5">
                        <h1 className="display-5 fw-bolder text-white mb-2">{t('text_slide')}</h1>                        
                    </div>
                    </div>
                </div>
                </div>

                <video autoPlay loop muted playsInline className="header-video">
                    <source src="https://pratdumas.com/public/img/pratdumas-acc.mp4" type="video/mp4" />                    
                    {/* <source src="/public/img/pratdumas-acc.mp4" type="video/mp4" /> */}
                    Your browser does not support the video tag.
                </video>
            </header>
        </>
    )
}

export default Header;