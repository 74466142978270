import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/Products.css';
import { BASE_URL } from "../Components/config/keys";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function HeaderProducts({ onSearch }) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation(); 
    const selectStyles = {
        border: '2px solid #38A3BC',
        color: 'white',
        backgroundColor: '#38A3BC',
        /* width: '350px' */
    };

    const [categories, setCategories] = useState([]);
    const [subcategories, setSubCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    useEffect(()=>{
        async function fetchData(){
            try {
                const response = await axios.get(BASE_URL+'getCategories?lang='+i18n.language);
                setCategories(response.data.data);     
            } catch (error) {
                console.error('Error fetching getCategories data:', error);
            }
        }
        
        fetchData();       
    }, [i18n.language]); 
    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);

        async function fetchData1(){
            try {
                const response = await axios.get(BASE_URL+'getSubCategories/'+e.target.value+'?lang='+i18n.language);
                console.log(response);
                setSubCategories(response.data.data);     
            } catch (error) {
                console.error('Error fetching getSubCategories data:', error);
            }
        }
        fetchData1();
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        navigate(`/produit-liste/${selectedCategory}`);
        // Pass the selected category to the parent component
        // onSearch({'category':selectedCategory, 'subcategory':selectedSubCategory});
    };

    const getsubfamilles = (e) => {
        setSelectedSubCategory(e.target.value);
    };

    
    return (
        <>
            <div className="header headre_pro py-5">
                <div className="container px-5">
                    <div className="row gx-2 mb-5 mt-2">
                        <div className="col-lg-6">
                            <form method='post' onSubmit={handleSubmit}>
                                <div className="form-group row fs-4 mb-3">
                                    <label className="col-7 col-form-label fw-bolder text-darck mt-0">{t('Search by Family')} &nbsp;&nbsp; :</label>
                                    <div className="col-5">
                                        <select className="form-control form-select" style={selectStyles} onChange={handleCategoryChange}>
                                            <option value="" >{t('our_products')} </option>
                                            {
                                                categories?.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.title}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>                                                             

                                {/* <div className="form-group row fs-4">
                                    <label className="col-7 col-form-label fw-bolder text-darck">{t('Search by Industry')} :</label>
                                    <div className="col-5">
                                        <select className="form-control form-select" style={selectStyles} onChange={getsubfamilles}>
                                            <option value="" >--{t('Choisir')}--</option>
                                            {
                                                subcategories?.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.title}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-end mt-3">
                                        <button type="submit" className="btn btn-primary fs-5 p-0"
                                            style={{ borderRadius: '50px', borderWidth: '2px', width: '100px' }}>
                                            {t('search')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div>
        </>
    )
}

export default HeaderProducts;