// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import HeaderProducts from './HeaderProducts';
// import Carousel from 'react-bootstrap/Carousel';
// import { BASE_URL, BASE_URL_PUBLIC } from "../Components/config/keys";
// import axios from 'axios';
// import { useTranslation } from 'react-i18next';

// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css'
// import CustomArrow from '../Components/CustomArrow';


// const Products = () => {
//     const { t, i18n } = useTranslation(); 
//     const [index, setIndex] = useState(0);
//     const [index1, setIndex1] = useState(0);
//     const [productsSelection, setProductsSelection] = useState(null);
//     const [productsIndustriel, setProductsIndustriel] = useState(null);
//     const [selectedCategory, setSelectedCategory] = useState('');
//     const handleSelect = (selectedIndex, e) => {
//         setIndex(selectedIndex);
//         if (selectedIndex === e?.length - 1) {
//             setTimeout(() => {
//             setIndex(0);
//             }, 0);
//         }
//     };
//     const handleSelect1 = (selectedIndex, e) => {
//         setIndex1(selectedIndex);
//         if (selectedIndex === e?.length - 1) {
//             setTimeout(() => {
//                 setIndex1(0);
//             }, 0);
//         }
//     };
//     async function fetchDataSelection(){
//         try {
//             const response = await axios.get(BASE_URL+'products/selection?lang='+i18n.language);
//             setProductsSelection(response.data.data);               
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     }
//     async function fetchDataIndustriel(){
//         try {
//             const response = await axios.get(BASE_URL+'products/industriel?lang='+i18n.language);
//             setProductsIndustriel(response.data.data);               
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     }
//     useEffect(()=>{       
//         fetchDataSelection();
//         fetchDataIndustriel();
//     },[i18n.language])

//     const handleSearch = async (categoryInfo) => {
//         setSelectedCategory(categoryInfo.category);
//         try {
//             const response = await axios.get(BASE_URL + `products-by-categorie/${categoryInfo.category}?subcategory=${categoryInfo.subcategory}&lang=${i18n.language}`);
//             setProductsSelection(response.data.data);
//         } catch (error) {
//             console.error('Error fetching products by category:', error);
//         }
//     };

//     if (!productsSelection) {
//         return <div>Loading...</div>;
//     }

  
//     const settings = {
//         dots: false,
//         arrows: true,
//         infinite: true,
//         autoplay: true,
//         autoplaySpeed: 5000,
//         slidesToShow: 3,
//         slidesToScroll: 3,
//         prevArrow: <CustomArrow direction="prev" />, // Custom arrow for previous slide
//         nextArrow: <CustomArrow direction="next" />,
//         responsive: [
//           {
//             breakpoint: 1024,
//             settings: {
//               slidesToShow: 2,
//               slidesToScroll: 2,
//               infinite: true,
//               dots: false,
//               arrows: true,
//               prevArrow: <CustomArrow direction="prev" />, // Custom arrow for previous slide
//               nextArrow: <CustomArrow direction="next" />,
//             },
//           },
//           {
//             breakpoint: 768,
//             settings: {
//               slidesToShow: 1,
//               slidesToScroll: 1,
//             },
//           },
//         ],
//       };

//       const settings1 = {
//         dots: false,
//         arrows: true,
//         infinite: true,
//         autoplay: true,
//         autoplaySpeed: 5000,
//         slidesToShow: 3,
//         slidesToScroll: 3,
//         prevArrow: <CustomArrow direction="prev" />, // Custom arrow for previous slide
//         nextArrow: <CustomArrow direction="next" />,
//         responsive: [
//           {
//             breakpoint: 1024,
//             settings: {
//               slidesToShow: 2,
//               slidesToScroll: 2,
//               infinite: true,
//               dots: false,
//               arrows: true,
//               prevArrow: <CustomArrow direction="prev" />, // Custom arrow for previous slide
//               nextArrow: <CustomArrow direction="next" />,
//             },
//           },
//           {
//             breakpoint: 768,
//             settings: {
//               slidesToShow: 1,
//               slidesToScroll: 1,
//             },
//           },
//         ],
//       };
//     return (
//         <>
//             <HeaderProducts onSearch={handleSearch}/>
//             <section className="pt-5 pb-5">
//                 <div className="container pb-5">
//                     <h2 className="h4 fw-bolder" style={{ color: '#324061' }}>{t('Our Product Selection')}</h2>
//                 </div>
//                 <div className="container">
//                     <div className="row">
//                     <Slider {...settings}>
//                         {productsSelection?.map((slide) => (
//                         <div key={slide.id} onSelect={handleSelect}>
//                                     <Link to={`/product_detail/${slide.id}`} className="text-decoration-none">     
//                                         <div className="" style={{ 
//                                             width: "auto", 
//                                             backgroundImage:encodeURI(`url(${BASE_URL_PUBLIC+'uploads/photos/articles/'+slide.photo})`) ,
//                                             backgroundSize:'contain',    
//                                             height: '250px',
//                                             backgroundRepeat: 'no-repeat',
//                                             backgroundPosition: 'center center'
                                            
//                                             }}>
//                                                 <Link to={`/product_detail/${slide.id}`} className="text-decoration-none">                                        
//                                                     {/* <img
//                                                         className="card-img-top img-fluid"
//                                                         src={BASE_URL_PUBLIC+'uploads/photos/articles/'+slide.photo}                                               
//                                                         alt="Card 1"
//                                                         style={{ height: "193px", width: "356px" }}
//                                                     /> */}
//                                                 </Link>
//                                         </div>
//                                     </Link>
//                                     <div className="card-body">                                                
//                                             <Link to={`/product_detail/${slide.id}`} className="text-decoration-none">    
//                                                 <p className='mb-0 mt-3 fw-bold title_card'>{slide.title}</p>
//                                             </Link>
//                                                 <p dangerouslySetInnerHTML={{ __html: slide.petit_description }}></p>
//                                                 <Link className="btn-outline-primary px-4 text-decoration-none float-end"
//                                                     to={`/product_detail/${slide.id}`}                    
//                                                     style={{ 
//                                                         color: '#04D9D9', border: '1px solid #04D9D9', borderRadius:'15px' 
//                                                     }}>
//                                                     {t('Voir')}
//                                                 </Link>
//                                     </div>
//                         </div>
//                         ))}
//                     </Slider>
//                     </div>
//                 </div>
//             </section>
//             <section className="py-0 pb-5">
//                 <div className="container pb-5">
//                     <h2 className="h4 fw-bolder" style={{ color: '#324061' }}>{t('Our Type of Industry Products')}</h2>
//                 </div>
//                 <div className="container">
//                     <div className="row">
//                     <Slider {...settings1}>
//                         {productsIndustriel?.map((slide) => (
//                         <div key={slide.id} onSelect={handleSelect}>
//                                     <Link to={`/product_detail/${slide.id}`} className="text-decoration-none">     
//                                         <div className="" style={{ 
//                                             width: "auto", 
//                                             backgroundImage:encodeURI(`url(${BASE_URL_PUBLIC+'uploads/photos/articles/'+slide.photo})`) ,
//                                             backgroundSize:'contain',    
//                                             height: '250px',
//                                             backgroundRepeat: 'no-repeat',
//                                             backgroundPosition: 'center center'
                                            
//                                             }}>
//                                                 <Link to={`/product_detail/${slide.id}`} className="text-decoration-none">                                        
//                                                     {/* <img
//                                                         className="card-img-top img-fluid"
//                                                         src={BASE_URL_PUBLIC+'uploads/photos/articles/'+slide.photo}                                               
//                                                         alt="Card 1"
//                                                         style={{ height: "193px", width: "356px" }}
//                                                     /> */}
//                                                 </Link>
//                                         </div>
//                                     </Link>
//                                     <div className="card-body">                                                
//                                             <Link to={`/product_detail/${slide.id}`} className="text-decoration-none">    
//                                                 <p className='mb-0 mt-3 fw-bold title_card'>{slide.title}</p>
//                                             </Link>
//                                                 <p dangerouslySetInnerHTML={{ __html: slide.petit_description }}></p>
//                                                 <Link className="btn-outline-primary px-4 text-decoration-none float-end"
//                                                     to={`/product_detail/${slide.id}`}                    
//                                                     style={{ 
//                                                         color: '#04D9D9', border: '1px solid #04D9D9', borderRadius:'15px' 
//                                                     }}>
//                                                     {t('Voir')}
//                                                 </Link>
//                                     </div>
//                         </div>
//                         ))}
//                     </Slider>
//                     </div>
//                 </div>
//             </section>
//         </>
//     )
// }

// export default Products;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HeaderProducts from './HeaderProducts';
import { BASE_URL, BASE_URL_PUBLIC } from "../Components/config/keys";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomArrow from '../Components/CustomArrow';

const Products = () => {
    const { t, i18n } = useTranslation(); 
    const [productsSelection, setProductsSelection] = useState(null);
    const [productsIndustriel, setProductsIndustriel] = useState(null);

    async function fetchDataSelection(){
        try {
            const response = await axios.get(BASE_URL + 'products/selection?lang=' + i18n.language);
            setProductsSelection(response.data.data);               
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    async function fetchDataIndustriel(){
        try {
            const response = await axios.get(BASE_URL + 'products/industriel?lang=' + i18n.language);
            setProductsIndustriel(response.data.data);               
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {       
        fetchDataSelection();
        fetchDataIndustriel();
    }, [i18n.language]);

    if (!productsSelection) {
        return <div>Loading...</div>;
    }

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: <CustomArrow direction="prev" />,
        nextArrow: <CustomArrow direction="next" />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                    arrows: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>
            <HeaderProducts />
            <section className="pt-5 pb-5">
                <div className="container pb-5">
                    <h2 className="h4 fw-bolder" style={{ color: '#324061' }}>{t('Our Product Selection')}</h2>
                </div>
                <div className="container">
                    <div className="row">
                        <Slider {...settings}>
                            {productsSelection?.map((slide) => (
                                <div key={slide.id}>
                                    <Link to={`/${i18n.language}/article/${i18n.language === 'fr' ? slide.url_fr : slide.url_en}`} className="text-decoration-none">     
                                        <div style={{ 
                                            width: "auto", 
                                            backgroundImage: `url(${BASE_URL_PUBLIC + 'uploads/photos/articles/' + slide.photo})`,
                                            backgroundSize: 'contain',    
                                            height: '250px',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center center'
                                        }}>
                                        </div>
                                    </Link>
                                    <div className="card-body">                                                 
                                        <Link to={`/${i18n.language}/article/${i18n.language === 'fr' ? slide.url_fr : slide.url_en}`} className="text-decoration-none">    
                                            <p className='mb-0 mt-3 fw-bold title_card'>{slide.title}</p>
                                        </Link>
                                        <p dangerouslySetInnerHTML={{ __html: slide.petit_description }}></p>
                                        <Link className="btn-outline-primary px-4 text-decoration-none float-end"
                                            to={`/${i18n.language}/article/${i18n.language === 'fr' ? slide.url_fr : slide.url_en}`}                    
                                            style={{ 
                                                color: '#04D9D9', border: '1px solid #04D9D9', borderRadius: '15px' 
                                            }}>
                                            {t('Voir')}
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
            {/* Répétez la même logique pour les produits industriels */}
            <section className="py-0 pb-5">
                <div className="container pb-5">
                    <h2 className="h4 fw-bolder" style={{ color: '#324061' }}>{t('Our Type of Industry Products')}</h2>
                </div>
                <div className="container">
                    <div className="row">
                        <Slider {...settings}>
                            {productsIndustriel?.map((slide) => (
                                <div key={slide.id}>
                                    <Link to={`/${i18n.language}/article/${i18n.language === 'fr' ? slide.url_fr : slide.url_en}`} className="text-decoration-none">     
                                        <div style={{ 
                                            width: "auto", 
                                            backgroundImage: `url(${BASE_URL_PUBLIC + 'uploads/photos/articles/' + slide.photo})`,
                                            backgroundSize: 'contain',    
                                            height: '250px',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center center'
                                        }}>
                                        </div>
                                    </Link>
                                    <div className="card-body">                                                 
                                        <Link to={`/${i18n.language}/article/${i18n.language === 'fr' ? slide.url_fr : slide.url_en}`} className="text-decoration-none">    
                                            <p className='mb-0 mt-3 fw-bold title_card'>{slide.title}</p>
                                        </Link>
                                        <p dangerouslySetInnerHTML={{ __html: slide.petit_description }}></p>
                                        <Link className="btn-outline-primary px-4 text-decoration-none float-end"
                                            to={`/${i18n.language}/article/${i18n.language === 'fr' ? slide.url_fr : slide.url_en}`}                    
                                            style={{ 
                                                color: '#04D9D9', border: '1px solid #04D9D9', borderRadius: '15px' 
                                            }}>
                                            {t('Voir')}
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Products;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useTranslation } from 'react-i18next';
// import Slider from 'react-slick';
// import CustomArrow from '../Components/CustomArrow';
// import { BASE_URL, BASE_URL_PUBLIC } from "../Components/config/keys";

// const Products = () => {
//     const { t, i18n } = useTranslation(); 
//     const [productsSelection, setProductsSelection] = useState(null);
//     const [productsIndustriel, setProductsIndustriel] = useState(null);

//     useEffect(() => {       
//         fetchDataSelection();
//         fetchDataIndustriel();
//     }, [i18n.language]);
//     useEffect(() => {       
//         fetchDataSelection();
//         fetchDataIndustriel();
//     }, [i18n.language]);

//     async function fetchDataSelection() {
//         try {
//             console.log('Fetching product selection...');
//             const response = await axios.get(`${BASE_URL}products/selection?lang=${i18n.language}`);
//             console.log('Product selection data:', response.data.data);
//             setProductsSelection(response.data.data);               
//         } catch (error) {
//             console.error('Error fetching product selection:', error);
//         }
//     }
    
//     async function fetchDataIndustriel(){
//         try {
//             console.log('Fetching industrial products...');
//             const response = await axios.get(`${BASE_URL}products/industriel?lang=${i18n.language}`);
//             console.log('Industrial products data:', response.data.data);
//             setProductsIndustriel(response.data.data);               
//         } catch (error) {
//             console.error('Error fetching industrial products:', error);
//         }
//     }

//     const logImageUrls = (products) => {
//         if (!products) {
//             console.log('No products data available.');
//             return;
//         }

//         products.forEach((product, index) => {
//             const imageUrl = `${BASE_URL_PUBLIC}uploads/photos/articles/${product.photo}`;
//             console.log(`Product ${index + 1} - Title: ${product.title}, Image URL: ${imageUrl}`);
//         });
//     };

//     const settings = {
//         dots: false,
//         arrows: true,
//         infinite: true,
//         autoplay: true,
//         autoplaySpeed: 5000,
//         slidesToShow: 3,
//         slidesToScroll: 3,
//         prevArrow: <CustomArrow direction="prev" />,
//         nextArrow: <CustomArrow direction="next" />,
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 2,
//                     infinite: true,
//                     dots: false,
//                     arrows: true,
//                     prevArrow: <CustomArrow direction="prev" />,
//                     nextArrow: <CustomArrow direction="next" />,
//                 },
//             },
//             {
//                 breakpoint: 768,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//         ],
//     };

//     return (
//         <>
//             <section className="pt-5 pb-5">
//                 <div className="container pb-5">
//                     <h2 className="h4 fw-bolder" style={{ color: '#324061' }}>{t('Our Product Selection')}</h2>
//                 </div>
//                 <div className="container">
//                     <div className="row">
//                         <Slider {...settings}>
//                             {productsSelection?.map((slide, index) => (
//                                 <div key={slide.id}>
//                                     <div className="" style={{ 
//                                         width: "auto", 
//                                         backgroundImage: `url(${BASE_URL_PUBLIC}uploads/photos/articles/${slide.photo})`,
//                                         backgroundSize: 'contain',    
//                                         height: '250px',
//                                         backgroundRepeat: 'no-repeat',
//                                         backgroundPosition: 'center center'
//                                     }}>
//                                         <img
//                                             className="card-img-top img-fluid"
//                                             src={`${BASE_URL_PUBLIC}uploads/photos/articles/${slide.photo}`}
//                                             alt={slide.title}
//                                             style={{ height: "193px", width: "356px", display: "none" }}
//                                         />
//                                     </div>
//                                     <div className="card-body">
//                                         <p className='mb-0 mt-3 fw-bold title_card'>{slide.title}</p>
//                                         <p dangerouslySetInnerHTML={{ __html: slide.petit_description }}></p>
//                                         <a
//                                             className="btn-outline-primary px-4 text-decoration-none float-end"
//                                             href={`/product_detail/${slide.id}`}
//                                             style={{ 
//                                                 color: '#04D9D9', border: '1px solid #04D9D9', borderRadius:'15px' 
//                                             }}
//                                         >
//                                             {t('Voir')}
//                                         </a>
//                                     </div>
//                                 </div>
//                             ))}
//                         </Slider>
//                     </div>
//                 </div>
//             </section>
//             <section className="py-0 pb-5">
//                 <div className="container pb-5">
//                     <h2 className="h4 fw-bolder" style={{ color: '#324061' }}>{t('Our Type of Industry Products')}</h2>
//                 </div>
//                 <div className="container">
//                     <div className="row">
//                         <Slider {...settings}>
//                             {productsIndustriel?.map((slide, index) => (
//                                 <div key={slide.id}>
//                                     <div className="" style={{ 
//                                         width: "auto", 
//                                         backgroundImage: `url(${BASE_URL_PUBLIC}uploads/photos/articles/${slide.photo})`,
//                                         backgroundSize: 'contain',    
//                                         height: '250px',
//                                         backgroundRepeat: 'no-repeat',
//                                         backgroundPosition: 'center center'
//                                     }}>
//                                         <img
//                                             className="card-img-top img-fluid"
//                                             src={`${BASE_URL_PUBLIC}uploads/photos/articles/${slide.photo}`}
//                                             alt={slide.title}
//                                             style={{ height: "193px", width: "356px", display: "none" }}
//                                         />
//                                     </div>
//                                     <div className="card-body">
//                                         <p className='mb-0 mt-3 fw-bold title_card'>{slide.title}</p>
//                                         <p dangerouslySetInnerHTML={{ __html: slide.petit_description }}></p>
//                                         <a
//                                             className="btn-outline-primary px-4 text-decoration-none float-end"
//                                             href={`/product_detail/${slide.id}`}
//                                             style={{ 
//                                                 color: '#04D9D9', border: '1px solid #04D9D9', borderRadius:'15px' 
//                                             }}
//                                         >
//                                             {t('Voir')}
//                                         </a>
//                                     </div>
//                                 </div>
//                             ))}
//                         </Slider>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default Products;
