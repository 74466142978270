import React, { useState, useEffect } from 'react';

import HomeCarousel_2 from './Carousel_2';
import Header from './Header'
import imageProduct from '../images/img-product.png'
import imagePartDumas from '../images/img-partDumas.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import { BASE_URL } from "./config/keys";
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import '../css/HomeStyle.css';
import { Link } from 'react-router-dom';

const Home = () => {
    const { t, i18n } = useTranslation();
    const [pageInfos, setPageInfos] = useState(null);
    useEffect(()=>{
        async function fetchData(){
            try {
                const response = await axios.get(BASE_URL+'gestion-de-page?lang='+i18n.language);
                setPageInfos(response.data.data);                     
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    },[i18n.language])
    return (
        <>
            {/* Header */}
            <Header />
            <section className="py-0" id="features">
                <div className="container px-3 pb-0 pt-5">
                    <div className="row gx-5">
                        <div className="div1 col-lg-6 mb-5 mb-lg-3">
                            {/* <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"></div> */}
                            <Link className="text-decoration-none" to="/part_dumas">
                            <h2 className="h4 fw-bolder" style={{ color: '#324061' }}> {t('prat_dumas')} </h2>
                            </Link>
                            {/* <img src={imageProduct} alt="...Image" className="img-fluid"/> */}
                            <Link className="text-decoration-none" to="/part_dumas">
                            <div className="col p-2" id="partDumasCard">
                                <h2 className=" fw-bolder mb-2">&nbsp;</h2>
                            </div>
                            </Link>
                            <Link className="text-decoration-none" to="/part_dumas">
                            <h5 className='title_card mt-3 mb-2 text-decoration-none' style={{ fontFamily: 'Poppins', fontWeight: 'bold'}}>{t('notre_savoir_faire')}</h5>
                            </Link>
                            <Link className="text-decoration-none" to="/part_dumas">
                            <p className='mb-0 w-75 text-start text-dark' dangerouslySetInnerHTML={{ __html: pageInfos?.notre_savoir_faire }}></p>
                            </Link>
                            <Link className="btn btn-outline-primary px-4 text-decoration-none float-end"
                                to="/part_dumas" style={{ borderColor: '#04D9D9', color: '#04D9D9', borderRadius: '50px', borderWidth: '2px' }}>
                                {t('view_all')}
                            </Link>
                        </div>
                        <div className="div2 col-lg-6 mb-5 mb-lg-3">
                            {/* <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-building"></i></div> */}
                            
                            <Link className="text-decoration-none" to="/products">
                            <h2 className="h4 fw-bolder text-decoration-none" style={{ color: '#324061' }}>{t('products')}</h2>
                            </Link>
                            {/* <img src={imagePartDumas} alt="...Image" className="img-fluid" /> */}
                            <Link className="text-decoration-none" to="/products">
                            <div className="col p-2" id="productCard">
                                <h2 className=" fw-bolder mb-2">&nbsp;</h2>
                            </div>
                            </Link>
                            <Link className="text-decoration-none" to="/products">
                            <h5 className='title_card mt-3 mb-2 text-decoration-none' style={{ fontFamily: 'Poppins', fontWeight: 'bold'}}>{t('our_products')} </h5>
                            </Link>
                            <Link className="text-decoration-none" to="/products">
                            <p className='mb-0 w-75 text-start text-dark' dangerouslySetInnerHTML={{ __html: pageInfos?.notre_produits }}></p>
                            </Link>
                            <Link className="btn btn-outline-primary px-4 text-decoration-none float-end"
                                to="/part_dumas" style={{ borderColor: '#04D9D9', color: '#04D9D9', borderRadius: '50px', borderWidth: '2px' }}>
                                {t('view_all')}
                            </Link>
                        </div>                        
                    </div>
                </div>
            </section>
            <section className="pb-5 pt-0">
                <div className="container pb-3">
                    <h2 className="h4 fw-bolder" style={{ color: '#324061' }}>{t('notre_catalogue')}</h2>
                </div>
                <div calss="container">
                    <HomeCarousel_2 />
                </div>
            </section>
        </>
    )
}
export default Home;